import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Header from '../Header/Header';
import { Typography } from '@material-ui/core';
import image from '../../Images/tarieven.jpg'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(3),
        marginBottom: '64px',
        marginLeft: theme.spacing(3),
    },
    grid: {
        marginBottom: '64px',
    },
    item: {
        paddingTop: theme.spacing(3),
    },
    text: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    image: {
        marginRight: theme.spacing(3),
        width: '90%',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3)
        }
    },
    topmargin: {
        paddingTop: theme.spacing(5),
        paddingRight: theme.spacing(3)
    },
    title: {
        paddingTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2)
        }
    }
}));

function Tarieven() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Header />
            <Typography variant="h4" color="secondary" align="center" className={classes.title}> Tarieven</Typography>
            <Grid container className={classes.grid} >
                <Grid item xs={12} md={8}>
                    <Grid container direction = "row">
                        <Grid item xs={9} lg={6}className={classes.topmargin}>
                            <Typography>Reiki behandeling (60 minuten):</Typography>
                        </Grid>
                        <Grid item xs={3} lg={6} className={classes.topmargin}>
                            <Typography>€25,-</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction = "row">
                    <Grid item xs={9} lg={6} className={classes.topmargin}>
                        <Typography>Stoelbehandeling (ongeveer 30 minuten): </Typography>
                    </Grid>
                    <Grid item xs={3} lg={6} className={classes.topmargin}>
                        <Typography>€15,-</Typography>
                    </Grid>
                    </Grid>
                    <Grid container direction = "row">
                    <Grid item xs={9} lg={6} className={classes.topmargin}>
                        <Typography>Reiniging (4 behandelingen op 4 achtereen volgende dagen):</Typography>
                    </Grid>
                    <Grid item xs={3} lg={6}className={classes.topmargin}>
                        <Typography>€75,-</Typography>
                    </Grid>
                    </Grid>
                </Grid>
                <Grid item align='center' xs={12} md={4}>
                    <img
                        src={image}
                        alt={'key'}
                        className={classes.image}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default (Tarieven);