import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Email from '@material-ui/icons/AlternateEmail';
import { mdiFacebook } from '@mdi/js';
import Icon from '@mdi/react';
import { withStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import IPhoneIcon from '@material-ui/icons/PhoneIphone';
import Link from '@material-ui/core/Link';
import Header from '../Header/Header';
import ContactImage from '../../Images/contact.jpg'

const styles = theme => ({
    icon: {
        marginRight: theme.spacing(1),
        verticalAlign: 'middle',
        color: theme.palette.secondary.main
    },
    zipcode: { marginBottom: theme.spacing(5) },
    item: { paddingTop: theme.spacing(3), paddingLeft: theme.spacing(3) },
    image: {
        width: '90%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            height: '600px',
            width: '400px'
        }
    },
});

function Contact(props) {
    const { classes } = props;
    return (
        <div>
            <Header />
            <Typography variant="h4" color="secondary" align="center" className={classes.title}> Contact</Typography>
            <Grid container className={classes.container}>
                <Grid item xs={12} md={6} className={classes.item}>
                    <Grid item xs={12}> <Typography color="secondary">Adres</Typography></Grid>
                    <Grid item xs={12}>
                        <Typography>Gerjanne Sessink</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Oude Lossersestraat 132</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.zipcode}>7574 DD Oldenzaal</Typography>
                    </Grid>
                    <Grid container direction="row">
                        <PhoneIcon className={classes.icon} />
                        <Typography>0541-517463</Typography>
                    </Grid>
                    <Grid container direction="row">
                        <IPhoneIcon className={classes.icon} />
                        <Typography>06-23776049</Typography>
                    </Grid>
                    <Grid container direction="row">
                        <Email className={classes.icon} /><Typography>gerjannesessink@hotmail.com</Typography>
                    </Grid >
                    <Grid container direction="row">
                        <Icon path={mdiFacebook} size={1} ccolor='secondary' className={classes.icon} />
                        <Link href="https://www.facebook.com/Reiki-Gerjanne-Sessink-901920756650524/" color="secondary">https://www.facebook.com/Reiki-Gerjanne-Sessink-901920756650524/</Link>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} align='center'>
                    <img
                        src={ContactImage}
                        alt={'key'}
                        className={classes.image}
                    />
                </Grid>
            </Grid>

        </div>
    );
}

export default withStyles(styles, { withTheme: true })(Contact);