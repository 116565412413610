import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
    typography: { useNextVariants: true },
    palette: {
        primary: { main: '#b2dfdb', light: '#ffffff', dark: '#b6b8c3' },
        secondary: { main: '#009688', light: '7953d2', dark: '#402F9A' },
        background: { default: '#FBFBFF' }
    }
});

export default theme;