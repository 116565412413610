import React from 'react';
import Footer from './Components/Footer/Footer'
import Content from './Components/Content/Content'
import theme from './theme'
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'

function App() {
  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Content/>
        <Footer />
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default App
