import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Header from '../Header/Header';
import BehandelingImage from '../../Images/Behandeling.jpg';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';

const styles = (theme) => ({
    text: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            align: "center",
        },
    },
    image: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        float: "right",
        width: "30%",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
            paddingBottom: theme.spacing(14),
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(2),
            width: "50%",
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(2),
            width: "35%",
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(2),
            width: "28%",
        },
    },
    title: {
        paddingTop: theme.spacing(2),
    },
    subTitle: {
        paddingTop: theme.spacing(6),
        fontWeight: "bold",
    },
    sum: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            align: "center",
        },
    },
    firstSum: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            align: "center",
        },
    }
});

function Behandeling(props) {

    const { classes } = props;

    return (
        <div>
            <Header />
            <Typography variant="h4" color="secondary" align="center" className={classes.title}> Behandeling</Typography>
            <Typography variant="h5" align="justify" color="textSecondary" className={classNames(classes.text, classes.subTitle)}>
                Hoe gaat een behandeling in zijn werk?
            </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" className={classes.text}>
                Een volledige behandeling wordt meestal liggend op een behandeltafel gegeven. Er worden verschillende handposities gebruikt
                aan de voor- en achterkant van het lichaam, zonder dat er druk wordt uitgeoefend. Op deze manier stroomt de energie door het hele lichaam en kan zo blokkades opheffen. Reiki werkt ontspannend en kan het zelfherstellende vermogen van het lichaam ondersteunen en versnellen. Reiki doet precies dat wat jij op dat moment nodig hebt. 
                Deze energie gaat door de kleding heen, dus die kun je gewoon aanhouden.
                Een stoelbehandeling is ook mogelijk. Dit duurt iets minder lang, maar is daardoor niet minder effectief.
            </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" className={classes.text}>
                Iedereen ervaart een behandeling weer anders. Het kan zijn dat je gaat trillen, of dat je het warm krijgt of juist koud.
                Er zijn ook mensen die helemaal niets voelen.
            </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" className={classes.text}>
                Na een behandeling is het aan te raden om veel water te drinken. Door de behandeling komen er veel afvalstoffen vrij en water
                drinken (of kruidenthee) helpt om die af te voeren en hoofdpijn te voorkomen.
            </Typography>
            <Typography variant="h5" align="justify" color="textSecondary" className={classNames(classes.text, classes.subTitle)}>
                Waarbij kan een reiki behandeling worden ingezet?
            </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" className={classes.text}>
                Reiki kan worden ingezet bij klachten op lichamelijk vlak, maar ook op mentaal en emotioneel niveau.
            </Typography>
            <Hidden xsDown>
                <img
                    src={BehandelingImage}
                    alt={'key'}
                    className={classes.image}
                />
            </Hidden>
            <Typography variant="h6" align="justify" color="textSecondary" className={classes.firstSum}>
                Een aantal voordelen van Reiki zijn:
            </Typography>
            <Typography variant="h6" color="textSecondary" className={classes.sum}>
                -	Diepe ontspanning
            </Typography>
            <Typography variant="h6" color="textSecondary" className={classes.sum}>
                -	Vermindert stress en angst
            </Typography>
            <Typography variant="h6" color="textSecondary" className={classes.sum}>
                -	Vermindert pijn
            </Typography>
            <Typography variant="h6" color="textSecondary" className={classes.sum}>
                -	Verlicht spierspanning
            </Typography>
            <Typography variant="h6" color="textSecondary" className={classes.sum}>
                -	Rust in je lichaam en geest
            </Typography>
            <Typography variant="h6" color="textSecondary" className={classes.sum}>
                -	Sneller herstel van letsel
            </Typography>
            <Typography variant="h6" color="textSecondary" className={classes.sum}>
                -	Brengt lichaam en energie in balans
            </Typography>
            <Typography variant="h6" color="textSecondary" className={classes.sum}>
                -	Ondersteunt het immuunsysteem
            </Typography>
            <Hidden smUp>
                <img
                    src={BehandelingImage}
                    alt={'key'}
                    className={classes.image}
                />
            </Hidden>
        </div>
    );
}

export default withStyles(styles)(Behandeling);