import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Header from '../Header/Header';
import Reiki from '../../Images/reiki.jpg';
import classNames from 'classnames';

const styles = theme => ({
    text: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        width: `calc(100vw - 48px)`,
    },
    image: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "50vw",
        marginTop: theme.spacing(9),

        [theme.breakpoints.down('sm')]: {
            width: "100vw",
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(5)
        },
    },
    italic: {
        fontStyle: "italic"
    },
    title: {
            paddingTop: theme.spacing(2),
    }
});

function Welcome(props) {
    const { classes } = props;

    return (
        <div className={classes.main}>
            <Header title="Reiki Gerjanne Sessink" />
            <Typography variant="h4" color="secondary" align="center" className={classes.title}> Wat is Reiki?</Typography>
            <Typography variant="h6" align="center" color="textSecondary" className={classes.text}>
                Reiki is een oude Japanse geneeswijze. Bij Reiki (Rei = universeel, ki = levensenergie) wordt doormiddel van handoplegging
                energie doorgegeven van de ene naar de andere persoon. De Reiki-methode die ik gebruik, is het oorspronkelijke Usui-systeem.
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" className={classes.text}>
                Reiki is eind 19e eeuw herontdekt door Dr. Mikao Usui, die oude boeddhistische geschriften bestudeerde waar de behandelmethode
                in beschreven stond. Na een lange zoektocht en 21 dagen mediteren en vasten op de berg Kurama, kreeg hij een visioen en werden
                hem de betekenis en het gebruik van de symbolen duidelijk. Hij besloot zij methode te delen met meer mensen dan alleen familie
                en een handje vol volgelingen.
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" className={classNames(classes.text, classes.italic)}>
                (bron: Het complete handboek Reiki, S. Nederlof en B. Buis)
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" className={classNames(classes.text, classes.italic)}>
                Mijn Reiki Master: Annet Boin
            </Typography>
            <img
                src={Reiki}
                alt={'key'}
                className={classes.image}
            />
        </div>)
}

export default withStyles(styles)(Welcome);