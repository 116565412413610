import React from 'react';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/AlternateEmail'
import Copyright from '@material-ui/icons/Copyright'
import Icon from '@mdi/react'
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { mdiFacebook } from '@mdi/js'
import Hidden from '@material-ui/core/Hidden';
const styles = theme => ({
    icon: {
        marginRight: theme.spacing(1),
        verticalAlign: 'middle'
    },
    appbar: {
        top: 'auto',
        bottom: '0',
    },
    email: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: theme.spacing(6)
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(3)
        },
        marginBottom: theme.spacing(1)
    },
    social: {
        [theme.breakpoints.up('sm')]: {
            maringLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    },
    number: {
        [theme.breakpoints.up('sm')]: {
            //paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(7)
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(3),
            textAlignLast: 'right'

        },
    },
    name: {
        [theme.breakpoints.up('sm')]: {
            flexGrow: 1,
            flexShrink: 0,
            paddingRight: theme.spacing(3)
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(1),
        }
    },
    toolbar: {
        [theme.breakpoints.down('xs')]: {
            display: 'inline-block'
        }
    },
    socialGrid: {
        [theme.breakpoints.up('md')]: {
            textAlignLast: 'right',
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(7)
        },
    }
});

function Footer(props) {
    const { classes, theme } = props;
    return (
        <footer>
            <AppBar position="fixed" className={classes.appbar}>
                <Toolbar className={classes.toolbar}>
                    <Hidden smUp>
                        <Grid container>
                            <Grid item xs={11} >
                                <Typography variant="body1" color="textSecondary" className={classes.name}>
                                    <Copyright color='secondary' className={classes.icon}></Copyright>Reiki Gerjanne Sessink
                                </Typography>
                                <Typography variant="body1" color="textSecondary" className={classes.number}>
                                    <Phone color='secondary' className={classes.icon}></Phone>
                                    0541-517463
                                </Typography>
                                <Typography variant="body1" color="textSecondary" className={classes.email}>
                                    <Email color='secondary' className={classes.icon}></Email>
                                    gerjannesessink@hotmail.com
                                </Typography>
                            </Grid>
                            <Grid item xs={1} className={classes.socialGrid}>
                                <Link href='https://www.facebook.com/pages/category/Alternative---Holistic-Health-Service/Reiki-Gerjanne-Sessink-901920756650524/' target='blank'>
                                    <Icon path={mdiFacebook} color={theme.palette.secondary.main} className={classes.social} size={1} />
                                </Link>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden xsDown mdUp>
                        <Grid container>
                            <Grid item sm={8}>
                                <Typography variant="body1" color="textSecondary" className={classes.name}>
                                    <Copyright color='secondary' className={classes.icon}></Copyright>Reiki Gerjanne Sessink
                                </Typography>
                            </Grid>
                            <Grid item sm={4}>
                                <Typography variant="body1" color="textSecondary" className={classes.number}>
                                    <Phone color='secondary' className={classes.icon}></Phone>
                                    0541-517463
                                </Typography>
                            </Grid>
                            <Grid item sm={8}>
                                <Typography variant="body1" color="textSecondary" className={classes.email}>
                                    <Email color='secondary' className={classes.icon}></Email>
                                    gerjannesessink@hotmail.com
                                </Typography>
                            </Grid>
                            <Grid item sm={4} className={classes.socialGrid}>
                                <Link href='https://www.facebook.com/pages/category/Alternative---Holistic-Health-Service/Reiki-Gerjanne-Sessink-901920756650524/' target='blank'>
                                    <Icon path={mdiFacebook} color={theme.palette.secondary.main} className={classes.social} size={1} />
                                </Link>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden smDown>
                        <Typography variant="body1" color="textSecondary" className={classes.name}>
                            <Copyright color='secondary' className={classes.icon}></Copyright>Reiki Gerjanne Sessink
                        </Typography>
                        <Typography variant="body1" color="textSecondary" className={classes.number}>
                            <Phone color='secondary' className={classes.icon}></Phone>
                            0541-517463
                        </Typography>
                        <Typography variant="body1" color="textSecondary" className={classes.email}>
                            <Email color='secondary' className={classes.icon}></Email>
                            gerjannesessink@hotmail.com
                        </Typography>
                        <Link href='https://www.facebook.com/pages/category/Alternative---Holistic-Health-Service/Reiki-Gerjanne-Sessink-901920756650524/' target='blank'>
                            <Icon path={mdiFacebook} color={theme.palette.secondary.main} className={classes.social} size={1} />
                        </Link>
                    </Hidden>
                </Toolbar>
            </AppBar >
        </footer >)
}

export default withStyles(styles, { withTheme: true })(Footer);