import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Header from '../Header/Header';
import Portret from '../../Images/overMij.jpg'
import classNames from 'classnames';

const styles = (theme) => ({
    text: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            align: "center",
        },
    },
    image: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        float: "left",
        width: "30%",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            paddingTop: theme.spacing(0),
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(2),
            width: "50%",
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(2),
            width: "28%",
        },
    },
    source: {
        fontStyle: "italic"
    },
    title: {
        paddingTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2)
        }
    }
});

function OverMij(props) {

    const { classes } = props;

    return (
        <div>
            <Header />
            <Typography variant="h4" color="secondary" align="center" className={classes.title}> Over Mij</Typography>
            <img
                src={Portret}
                alt={'key'}
                className={classes.image}
            />
            <Typography variant="h6" align="justify" color="textSecondary" className={classes.text}>
                Mijn naam is Gerjanne Sessink.
                Het is alweer een tijd geleden dat ik in aanraking kwam met Reiki. Dat vond ik een hele fijne methode. Ik vond het toen
                vooral een mooie en snelle manier om te ontspannen en tot rust te komen, nu weet ik en heb ik inmiddels zelf ervaren,
                dat er nog veel meer voordelen aan zitten.
                    </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" className={classes.text}>
                Na een aantal behandelingen ben ik  afgestemd op Reiki 1, zodat ik mezelf ook af en toe kon behandelen.
                In november 2015 ben ik afgestemd op Reiki 2 en inmiddels, na regelmatig oefenen met een groepje, ben ik zelf ook
                mensen gaan behandelen.
                    </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" className={classes.text}>
                Reiki heeft mij vooral heel veel rust gegeven in mijn hoofd, waardoor ik dingen beter kan overzien en ook beter aanvoel
                waar mijn grenzen liggen. Hierdoor heb ik nu ook de ruimte om dingen te verwerken en te zien wat er wel kan en wel goed
                gaat. En dat werkt natuurlijk weer door in mijn zelfvertrouwen en het vertrouwen in andere mensen. Een heel mooi proces
                dus. Ik heb er heel veel baat bij gehad en ik vind het heel fijn dat ik nu zelf mensen op deze manier kan en mag helpen
                om weer in hun eigen kracht te komen. Een goed gevoel over jezelf, dat verdient iedereen.
                    </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" className={classes.text}>
                Voor meer informatie kun je contact opnemen via deze site, mail, facebook, of telefonisch.
                    </Typography>
            <Typography variant="h6" align="justify" color="textSecondary" className={classNames(classes.text, classes.source)}>
                (Foto: Twentse Courant/Tubantia januari 2019)
                    </Typography>
        </div>
    );
}

export default withStyles(styles)(OverMij);