import React from 'react';
import { Route } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Reiki from '../Reiki/Reiki';
import Tarieven from '../Tarieven/Tarieven';
import Welcome from '../Welcome/Welcome';
import OverMij from '../OverMij/OverMij';
import Behandeling from '../Behandeling/Behandeling'
import Contact from '../Contact/Contact'

const styles = theme => ({
    content: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(10),
        },
        marginBottom: theme.spacing(14),
        marginTop: theme.spacing(11),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(7),
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(11),
        },
    }
});

function Content(props) {
    const { classes } = props;

    return (
        <div className={classes.content}>
            <Route path="/" exact component={Welcome} />
            <Route path="/reiki" component={Reiki} />
            <Route path="/tarieven" component={Tarieven} />
            <Route path="/overmij" component={OverMij} />
            <Route path="/behandeling" component={Behandeling} />
            <Route path="/contact" component={Contact}/>
        </div>
    )
}

export default withStyles(styles)(Content);