import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Header from '../Header/Header';
import Water from '../../Images/image_home.png';

const styles = theme => ({
    text: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(9),
        width: `calc(100vw - 48px)`,

        [theme.breakpoints.up('sm')]: {
            width: "50%",
            marginLeft: "25%",
            marginBottom: theme.spacing(16),
            marginTop: theme.spacing(20),
        },
    },
    image: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "50vw",

        [theme.breakpoints.down('sm')]: {
            width: "100vw",
            marginBottom: theme.spacing(3),
            marginTop:"15%"
        },
      }
});

function Welcome(props) {
    const { classes } = props;

    return (
        <div className={classes.main}>
            <Header title="Reiki Gerjanne Sessink" />
                    <Typography variant="h6" align="center" color="textSecondary" className={classes.text}>
                        Stress, angst en een ‘vol hoofd’ zijn denk ik voor veel mensen wel heel herkenbaar.
                        Tijdens een Reiki behandeling mag ik je een impuls meegeven, zoals (zelf)vertrouwen, innerlijke kracht, innerlijke rust. Dat wat jij op dat moment nodig hebt.
                        Ben jij op zoek naar meer rust in je hoofd, of heb je lichamelijke klachten? Dan help ik je graag.
                    </Typography>
                    <img 
                        src={Water}
                        alt={'key'}
                        className={classes.image}
                    />
        </div>)
}

export default withStyles(styles)(Welcome);