import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '../Shared/Menu'
import { Typography, Grid } from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center'
    },
    company: {
        marginTop: `${theme.spacing(1)}px`,
    },
    
    menu: {
        marginTop: `${theme.spacing(1.5)}px`,
    }
});

function Header(props) {
    return (
        <header className={props.classes.root}>
            <AppBar position="fixed">
                <Toolbar >
                    <Grid container>
                    <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={8} >
                            <Typography variant="h5" color="secondary" className={props.classes.company}>
                                Reiki Gerjanne Sessink
                                </Typography>
                        </Grid>
                        <Grid item xs={2} >
                            <Menu className={props.classes.menu}/>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </header>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);